
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('org_pro_division.division')"
                        label-for="division_id"
                        >
                        <v-select name="division_id"
                            v-model="search.division_id"
                            label="text"
                            :options= divisionList
                            :reduce="item => item.value"
                            :placeholder="$t('globalTrans.select')"
                            />
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('warehouse_config.region')"
                    label-for="division_id"
                    >
                    <v-select name="region_id"
                            v-model="search.region_id"
                            label="text"
                            :options= regionList
                            :reduce="item => item.value"
                            :placeholder="$t('globalTrans.select')"
                            />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_district.district')"
                    label-for="district_id"
                    >
                    <v-select name="district_id"
                        v-model="search.district_id"
                        label="text"
                        :options= regionDistrictLists
                        :reduce="item => item.value"
                        :placeholder="$t('globalTrans.select')"
                        />
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_upazilla.upazilla')"
                    label-for="upazilla_id"
                    >
                    <v-select name="upazilla_id"
                        v-model="search.upazilla_id"
                        label="text"
                        :options= upazilaList
                        :reduce="item => item.value"
                        :placeholder="$t('globalTrans.select')"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_union.union')"
                    label-for="union_id"
                    >
                    <v-select name="union_id"
                            v-model="search.union_id"
                            label="text"
                            :options= unionList
                            :reduce="item => item.value"
                            :placeholder="$t('globalTrans.select')"
                            />
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="commodity_group_id"
                    :label="$t('warehouse_information.warehouse_name')"
                    >
                    <v-select name="warehouse_id"
                            v-model="search.warehouse_id"
                            label="text"
                            :options= warehouseList
                            :reduce="item => item.value"
                            :placeholder="$t('globalTrans.select')"
                            />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-button type="button" variant="primary float-right" @click="searchData">Search</b-button>
                </b-col>
            </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_information.warehouseServiceList') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                    {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      {{ data.item.division_name }}
                    </template>
                    <template v-slot:cell(distict_name)="data">
                      {{ data.item.distict_name }}
                    </template>
                    <template v-slot:cell(upzila_name)="data">
                      {{ data.item.upzila_name }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      {{ data.item.warehouse_name }}
                    </template>
                    <template v-slot:cell(remarks)="data">
                      {{ data.item.remarks }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                      <b-badge variant="danger" v-if="data.item.status == 1">{{$t('globalTrans.inactive')}}</b-badge>
                      <b-badge variant="success" v-else>{{$t('globalTrans.active')}}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                      <!-- <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Warehouse Service Modify" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                          <i class="ri-ball-pen-fill"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Active" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                          <i class="fas fa-toggle-off"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Click to Deactive" size="sm" @click="remove(data.item)" v-else>
                          <i class="fa fa-toggle-on"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-if="pagination.totalRows"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" ok-title="Close" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>

  </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseServiceList, warehouseServiceListToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        division_id: '',
        region_id: '',
        district_id: '',
        upazilla_id: '',
        union_id: '',
        warehouse_id: ''
      },
      warehouseList: [],
      upazilaList: [],
      unionList: [],
      districtList: [],
      regionDistrictLists: []
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('warehouse_information.warehouseServiceEntry') : this.$t('warehouse_information.warehouse_service_update')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('warehouse_information.division'), class: 'text-center' },
          { label: this.$t('warehouse_config.region'), class: 'text-center' },
          { label: this.$t('warehouse_information.district'), class: 'text-center' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-center' },
          { label: this.$t('warehouse_information.warehouse_name'), class: 'text-center' },
          { label: this.$t('warehouse_information.remarks'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'region_name_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'remarks_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'region_name' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'warehouse_name' },
          { key: 'remarks' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    },
    regionList: function () {
      let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
      if (this.isWareHouseUser) {
        regionList = regionList.filter(item => item.value === this.search.region_id)
      }
      return regionList
    }
  },
   watch: {
    'search.division_id': function (newVal, oldVal) {
      this.regionDistrictLists = this.getDistrictList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    }

  },
  async created () {
  },
  async mounted () {
    if (this.isWareHouseUser) {
      this.search.division_id = this.authUser.office_detail.division_id
      this.search.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.search.district_id = this.authUser.office_detail.district_id
      this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.search.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.search.warehouse_id = warehouse.value
    }
    this.loadData()
  },
  methods: {
    async searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(warehouseServiceBaseUrl, warehouseServiceListToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(warehouseServiceBaseUrl, warehouseServiceList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.ListData(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getDistrictList (divisionId = null) {
      const regionDistrictLists = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return regionDistrictLists.filter(district => district.division_id === divisionId)
      }

      return regionDistrictLists
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (unionId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (unionId) {
        return unionList.filter(union => union.upazilla_id === unionId)
      }

      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }
      return list
    },
    ListData (data) {
      let tmpData = {}
      let tmpData2 = {}
      let tmpUpzila = {}
      let tmpWarehouseData = {}
      const listData = data.map(item => {
        tmpData = this.$store.state.commonObj.divisionList.find(division => division.value === item.division_id && division.status === 0)
        tmpData2 = this.$store.state.commonObj.districtList.find(district => district.value === item.district_id && district.status === 0)
        tmpUpzila = this.$store.state.commonObj.upazilaList.find(upazila => upazila.value === item.upazilla_id && upazila.status === 0)
        tmpWarehouseData = this.$store.state.warehouse.warehouseInfoList.find(wareItem => wareItem.value === item.warehouse_id && wareItem.status === 0)
        const serviceListData = {
          division_name: tmpData !== undefined ? tmpData.text_en : '',
          division_name_bn: tmpData !== undefined ? tmpData.text_bn : '',
          distict_name: tmpData2 !== undefined ? tmpData2.text_en : '',
          distict_name_bn: tmpData2 !== undefined ? tmpData2.text_bn : '',
          upzila_name: tmpUpzila !== undefined ? tmpUpzila.text_en : '',
          upzila_name_bn: tmpUpzila !== undefined ? tmpUpzila.text_bn : '',
          warehouse_name: tmpWarehouseData !== undefined ? tmpWarehouseData.text : '',
          warehouse_name_bn: tmpWarehouseData !== undefined ? tmpWarehouseData.text_bn : ''
        }
        return Object.assign({}, item, serviceListData)
      })
      return listData
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.regionDistrictLists.push(item)
            }
          })
        }
      })
    }
  }
}
</script>
