<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <ValidationProvider name="Division" rules="required|min_value:1">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('warehouse_config.division') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="serviceData.division_id"
                      :options="divisionList"
                      id="division_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="serviceData.region_id"
                      :options="regionLists"
                      id="region_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="District" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="district"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('warehouse_config.district') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="serviceData.district_id"
                      :options="regionDistrictLists"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Upazila" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazilla_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('warehouse_config.upazilla') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="serviceData.upazilla_id"
                      :options="upazilaList"
                      id="upazilla_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Union" rules="">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="union_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('globalTrans.union') }} <span class="text-danger"></span>
                    </template>
                    <b-form-select
                      plain
                      v-model="serviceData.union_id"
                      :options="unionList"
                      id="union_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                     <ValidationProvider name="Warehouse Name" vid="warehouse_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="warehouse_name"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('warehouse_config.warehouse')}} <span class="text-danger">*</span>
                      </template>
                        <b-form-select
                          plain
                          v-model="serviceData.warehouse_id"
                          :options="warehouseNameList"
                          id="warehouse_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :disabled="isWareHouseUser"
                          >
                          <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                          </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Attached file" vid="attachment">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="attachment"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_config.attached_file')}}
                         </template>
                        <b-form-file
                          id="attachment"
                          v-on:change="onFileChange"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Remarks (En)" vid="remarks">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="remarks"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('warehouse_config.remarksf')}}
                      </template>
                      <b-form-textarea
                          id="remarks"
                          v-model="serviceData.remarks"
                          placeholder="Enter Remarks"
                          rows="1"
                          max-rows="2"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                </ValidationProvider>
                     <ValidationProvider name="Remarks (Bn)">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="remarks_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_config.remarks_Bn')}}
                      </template>
                      <b-form-textarea
                          id="remarks"
                          v-model="serviceData.remarks_bn"
                          placeholder="Enter Remarks (BN)"
                          rows="1"
                          max-rows="2"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-textarea>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl, warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseServiceStore, warehouseServiceUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getWareServiceData()
      this.serviceData = tmp
    }
  },
  mounted () {
    core.index()
    if (this.isWareHouseUser) {
      this.serviceData.division_id = this.authUser.office_detail.division_id
      this.serviceData.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.serviceData.district_id = this.authUser.office_detail.district_id
      this.serviceData.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.serviceData.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.serviceData.warehouse_id = warehouse.value
    }
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      serviceData: {
        division_id: '0',
        region_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0',
        commodity_group_id: 0,
        commodity_name_id: 0,
        attachment: [],
        warehouse_id: '0',
        remarks: '',
        remarks_bn: ''
      },
      attachment_two: [],
      districtList: [],
      upazilaList: [],
      categoryList: [],
      unionList: [],
      warehouseNameList: [],
      regionDistrictLists: []
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionLists: function () {
      return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    }
  },
  watch: {
     'serviceData.division_id': function (newVal, oldVal) {
      this.regionDistrictLists = this.getDistrictList(newVal)
    },
    'serviceData.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'serviceData.district_id': function (newVal, oldVal) {
      this.getUpazilaList(newVal)
    },
    'serviceData.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseNameList = this.getWarehouseName(newVal, 'UPZ')
    },
    'serviceData.union_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal, 'UP')
    }
  },
  methods: {
    getWareServiceData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    onFileChange (e) {
      this.attachment_two = e.target.files[0]
    },
    async register () {
      let result = null
      const loadinState = { loading: false, listReload: true }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.serviceData).map(key => {
        if (key === 'attachment') {
          formData.append(key, this.attachment_two)
        } else {
          formData.append(key, this.serviceData[key])
        }
      })
      if (this.serviceData.id) {
        result = await RestApi.postData(warehouseServiceBaseUrl, `${warehouseServiceUpdate}/${this.id}`, formData)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, warehouseServiceStore, formData, config)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data Update successfully' : 'Data saved successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }
      return list
    },
    getUnionList (unionId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (unionId) {
        return unionList.filter(union => union.upazilla_id === unionId)
      }

      return unionList
    },
    getDistrictList (divisionId = null) {
      const regionDistrictLists = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return regionDistrictLists.filter(district => district.division_id === divisionId)
      }

      return regionDistrictLists
    },
    getUpazilaList (districtId = null) {
      const data = {
        division_id: this.serviceData.division_id,
        district_id: this.serviceData.district_id
      }
      RestApi.postData(commonServiceBaseUrl, '/division-district-matching', data).then(response => {
        if (!response.success) {
          this.$toast.error({
            title: 'Error',
            message: response.message
          })
        }
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (districtId) {
          const upazilaListFiltered = upazilaList.filter(upazila => upazila.district_id === districtId)
          this.upazilaList = upazilaListFiltered
        } else {
          this.upazilaList = upazilaList
        }
      })
    },
     getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.regionDistrictLists.push(item)
            }
          })
        }
      })
    }
  }
}

</script>
